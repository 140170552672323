const eulaSteps = [
  {
    type: "h1",
    text: "1. Taraflar",
  },
  {
    type: "p",
    text: "Bu sözleşme Bilkent Cyberpark C Blok No Z50 Çankaya Ankara adresinde mukim Seneka Yazılım Donanım Bilişim Ticaret Taahhüt Ve San. Ltd. Şti. (bu sözleşmede Seneka olarak anılacaktır) ile Onaylarım’dan faydalanacak müşteri (bu sözleşmede Müşteri olarak anılacaktır) arasındaki görev, sorumluluk, hak ve yükümlülükleri belirlemektedir.",
  },
  {
    type: "p",
    text: "Sözleşme kapsamında Seneka ve Müşteri ayrı ayrı “Taraf” birlikte ise “Taraflar” olarak anılacaklardır.",
  },
  {
    type: "h1",
    text: "2. Sözleşme’nin Konusu",
  },
  {
    type: "p",
    text: "İşbu Sözleşme’nin konusu; Müşteri’nin, Seneka tarafından sunulan, dokümanların e-imza atılması, istenilen dokümanların platform üzerinden paylaşılmasını, görüntülenmesini, elektronik imza, mobil imza veya dijital imza ile imzalanmasını ve istenilmesi durumunda dış sistemlere aktarılmasını sağlayan Uygulama’yı kullanmasına ilişkin olarak Taraflar’ın hak ve yükümlülüklerini düzenlemektir.",
  },
  {
    type: "h1",
    text: "3. Genel Yükümlülükler",
  },
  {
    type: "p",
    text: "Taraflar genel ticari yasa, ahlak ve düsturlara göre hareket edeceğini, iyi niyet çerçevesinde karşılıklı çıkarlarını koruyacağını ve ticari faaliyetlerini tamamıyla T.C. kanunlarına uygun olarak sürdüreceğini kabul ve taahhüt eder.",
  },
  {
    type: "p",
    text: "Müşteri, kendisinin, bağlı ya da bağlantılı olduğu diğer şirket, kurumların ve gerçek kişilerin Türkiye Cumhuriyeti kanunlarına, yönetmeliklerine, iş ahlakı kurallarına geçmişte uygun davrandığını beyan ederek, üyelik süresince gelecekte de uygun davranmaya devam edeceğini kabul ve taahhüt etmiştir.",
  },
  {
    type: "p",
    text: "Müşteri sözleşmeden kaynaklanan hak ve yükümlülüklerini kısmen veya tamamen devredemez, temlik edemez.",
  },
  {
    type: "p",
    text: "Taraflar, Kişisel Verilerin Korunması Kanunu, Fikir ve Sanat Eserleri Yasası, Markaların Korunması Hakkındaki Kanun Hükmünde Kararname, Türk Ticaret Yasası, Patent Haklarının Korunması Hakkındaki Kanun Hükmünde Kararname, Türk Ceza Yasası ve diğer ilgili yasaların hükümlerine uymayı baştan kabul, beyan ve taahhüt eder.",
  },
  {
    type: "p",
    text: "Müşteri’nin işbu sözleşmede yazılı kurallara aykırı hareket etmesi durumunda oluşacak hukuki ve mali sonuçlardan tamamen Müşteri sorumludur. Seneka, Müşteri’nin Onaylarım ile yaptığı faaliyetlerden sorumlu değildir. Müşteri, Seneka'nın oluşabilecek hukuki ve mali sonuçlarla herhangi bir ilgisinin olmadığını kabul ve beyan etmektedir.",
  },
  {
    type: "p",
    text: "Seneka'nın kendisinden kaynaklanmayan Müşteri’nin kendi tarafında oluşacak yazılım, donanım, internet bağlantısı, altyapı gibi tüm teknik aksaklıkları gidermek tamamen Müşteri’nin yükümlülüğündedir. Müşteri’den kaynaklı aksamalardan dolayı Seneka'nın hiçbir sorumluluğu olmayacaktır.",
  },
  {
    type: "p",
    text: "Seneka'nın kurumsal internet sayfasında, sosyal medya sayfalarında ve diğer tüm tanıtım ve basın mecralarında Müşteri’nin müşterisi olduğunu açıklama ve Müşteri’nin ismini, unvanını, markasını, logosunu kullanma hakkı ve yetkisi vardır. Bu yetki isim, marka, logo görselini kullanma ve ilgili sisteme link verme şeklinde de kullanılabilir.",
  },
  {
    type: "p",
    text: "Seneka'nın bu sözleşme kapsamında sağladığı hizmetlerin, Seneka'nın ve İş Ortaklarının sunucularının ve sistemlerinin bulunduğu veri merkezlerinden (datacenter), İnternet Servis Sağlayıcılar veya Telekomünikasyon şirketlerden kaynaklanan nedenlerle kısmen veya tamamen durması nedeniyle oluşacak hizmet ve diğer kayıplardan Seneka hiçbir surette sorumlu olmayacaktır. Böyle bir sorunun oluşması durumunda ve Müşteri’nin talep etmesi halinde Seneka ilgili veri merkezinden, internet servis sağlayıcıdan veya telekom şirketinden ilgili soruna dair açıklama alacak ve Müşteri’yle paylaşacaktır.",
  },
  {
    type: "p",
    text: "Seneka'nın bu sözleşme kapsamında sağladığı hizmetlerin siber saldırı, sosyal mühendislik, sistem ele geçirilme vb. herhangi bir nedenle durmasından dolayı oluşacak hizmet ve diğer kayıplardan Seneka sorumlu olmayacaktır. Böyle bir durumda Müşteri’nin talep etmesi halinde Seneka ilgili sorunla ilgili Müşteri’ye yazılı açıklama gönderecektir.",
  },
  {
    type: "p",
    text: "Uygulamaya girmek için kullanılacak olan kullanıcı adı/şifre/parola, e-imza/mobil imza, elektronik kimlik kartı vb. kimlik belirleme Müşteri tanımlama araçlarının korunmasının tüm sorumluluğu tamamen ve yalnızca Müşteri’ye aittir. Müşteri bu giriş ve kimlik belirleme araçları kullanılarak Uygulamada yapılacak tüm işlemlerden tamamen kendisinin sorumlu olacağını kabul, beyan ve taahhüt eder.",
  },
  {
    type: "p",
    text: "Seneka'nın Uygulama’da oluşturulan ve/veya paylaşılan belge ve bilgilere ilişkin herhangi bir sorumluluğu bulunmamaktadır. Müşteri, Uygulama’da oluşturulan tüm bilgilerin doğru ve hukuka uygun olduğunu kabul ve taahhüt etmektedir. Uygulama’da oluşturulan kayıtların mevzuatlara uygun olması, bilgilerin yedeğinin alınması ve bunlarla ilişkili her türlü sorumluluk Müşteri’ye aittir.",
  },
  {
    type: "p",
    text: "Müşteri Seneka'nın uygulama ve hizmetlerde istediği zaman ve tamamen kendi takdirine bağlı olarak değişiklik yapabileceğini, var olan hizmetleri kaldırabileceğini ve yenilerini ekleyebileceğini kabul eder.",
  },
  {
    type: "p",
    text: "Seneka, sunulmakta olan uygulama ve hizmetleri, değiştirme veya bunlardan farklı hizmetleri sunma hakkını saklı tutar. Bu hizmetler Seneka'nın takdirine göre ücretli veya ücretsiz olabileceği gibi, tamamen Seneka'nın takdiri ile bazı üyelere sunulup bazı üyelere sunulmayabilir. Müşteri, ücretli hizmetler konusunda Tüketicinin Korunması Hakkında Kanun ile getirilen hakları saklı kalmak kaydıyla, hizmetlerin değişmesi, sonlandırılması, yeni hizmetler konusunda kendisine izin verilmemesi veya sınırlı izin verilmesi nedeniyle herhangi bir hak ve tazminat talep edemez.",
  },
  {
    type: "p",
    text: "Seneka, üyelerinin vermiş oldukları bilgilerin gerçekliğini doğrulamaz ve bu nedenle üyenin profilinde belirtmiş olduğu kişi olduğu, güvenilirliği, yaşı, cinsiyeti vb. konularda sadece üyenin vermiş olduğu bilgilerle sınırlı bir bilgisi vardır. Bu nedenle üyelerin Seneka Onaylarım ve hizmetlerini referans göstererek görüştükleri 3. kişilere verdikleri bilgilerin gerçek olmaması nedeni ile yaşanabilecek sorunlardan Seneka hiçbir şekilde sorumlu tutulamaz. Onaylarım, Müşterilere hizmet sunan bir platform olup Müşterilerin gerçek bilgilerini araştırmamakta, kendisine kayıtta verilen bilgilere itimat etmektedir. Bu nedenle Müşteriler platform içerisinde ve/veya gerçek hayatta müşterileri ve diğer Müşterilere girecekleri tüm ilişkilerde kişisel güvenlik ve gizliliklerini korumak için gerekli tüm tedbirleri almakla yükümlüdürler.",
  },
  {
    type: "p",
    text: "Müşteri’nin, Uygulama ve hizmetleri almaya başlamadan önce kendini platforma kaydetmesi gerekmektedir. Seneka sahte üyelik hesaplarıyla açılmış olduğunu fark ettiği hesapları kapatacaktır. Müşteri bu nedenle hesabının kapatılmasından dolayı herhangi bir talepte bulunmamayı kabul ve taahhüt eder.",
  },
  {
    type: "p",
    text: "Müşteri bir başkasının adı, markası, işletme adı ve benzer şekilde kamuoyunda bir başkası ile özdeşleşmiş isimler ile platforma kayıt olamaz.",
  },
  {
    type: "p",
    text: "Müşteri, kayıt anında reşit olduğunu taahhüt eder.",
  },
  {
    type: "p",
    text: "Müşteri kayıt sırasında vermiş olduğu bilgi ve içeriklerin bir başkasının haklarına zarar vermediğini ve yürürlükteki yasalara, ahlaka ve adaba aykırı olmadığını taahhüt eder. Bu taahhüdün ihlali durumunda Seneka Müşteri’ye haber vermeden derhal üyeliği sonlandırma hakkına sahiptir.",
  },
  {
    type: "p",
    text: "Müşteri, diğer Müşteriler ile platform üzerinden paylaşacağı her türlü dosya ve bağlantının bu kullanım koşullarına ve yasalara uygun, diğer tarafı rahatsız etmeyecek olmasına dikkat eder. Bu kurala uymayan Müşteri’nin sözleşmesi derhal sonlandırılır. Seneka, Müşterisi ve diğer Müşteriler ile arasındaki iletişime taraf olmadığından ve bu iletişimi kontrol ermediğinden Müşterilerin birbirlerine gönderecekleri tüm içerik veya bağlantı nedeniyle uğrayabilecekleri zararlardan Seneka sorumlu tutulamaz.",
  },
  {
    type: "p",
    text: "Müşteri, Onaylarım’ı kullanırken işbu sözleşmenin koşullarına uygun davranmayı, aksi halde sözleşmesinin derhal sonlandırılarak Uygulamaya girişinin engelleneceğini ve peşin ödenen ücretlerin iadesi de dahil olmak üzere hiçbir talepte bulunamayacağını kabul ve taahhüt eder. Seneka'nın gerek kendisinin bu nedenle uğrayacağı gerekse üçüncü şahısların kendisinden bu ihlal nedeniyle tahsil edeceği tüm zararları, cezaları, tazminatları ve ilgili tüm masrafları tamamen Müşteri’den talep etme hakkı saklıdır.",
  },
  {
    type: "p",
    text: "Hizmetlerin kullanılabilmesi için Müşteri tarafından yapılması gereken tüm başvuruların eksiksiz ve hatasız yapılması, e-imza, mobil imza, kredi kartı, internet erişimi vb. tüm hizmet ve ürün bileşenlerinin temin edilmesi tamamen ve sadece Müşteri’nin yükümlülüğündedir. Uygulamayı kullanmak için gerekli tüm bileşenlerin temin edilmesinde ve gerekliliklerinin sağlanmasında veya ilgili tüm hususların bir veya birkaçının eksik yapılması veya ihlali nedeniyle sözleşme konusu hizmetin Müşteri’ye kısmen veya tamamen verilememesi durumunda, Seneka hiçbir surette sorumlu olmayacak, Müşteri tarafından seçilmiş veya taahhüt edilmiş ilgili hizmet dönemine ilişkin tüm uygulama kullanım bedeline ve ilgili hizmet bedeline hak kazanacaktır.",
  },
  {
    type: "p",
    text: "Müşteri Uygulamanın doğru işleyebilmesi için girilmesi gereken tüm bilgileri kendisi belirlemekle yükümlüdür. Bu işlemlerde yapılacak hatalardan meydana gelecek zararlar Seneka sorum değildir.",
  },
  {
    type: "p",
    text: "Müşteri platformda yer alacak profil oluşturma, yazışma ve benzeri başlıklara ilişkin alanları yalnızca bu alanların kullanım amacıyla bağlantılı mesaj ve materyal göndermek amacıyla kullanmayı ve bu sayılanlarla sınırlı olmamakla birlikte kullanımlarını aşağıda sayılan sonuçları doğuracak biçimde gerçekleştirmeyeceğini kabul, beyan ve taahhüt eder. Müşterinin hizmetleri kullanımı sırasında aşağıda örnek olarak sayılmış olan ve fakat sınırlayıcı olmayan davranışlar veya yürürlükteki mevzuata veya genel ahlak ve adaba aykırı, toplumu veya diğer üyeleri rahatsız edici davranışlarda bulunması Seneka'ya Müşterinin sözleşmesini derhal feshetme hakkı verir. Müşteri bu durumda herhangi bir tazminat talebinde bulunamayacağı gibi Seneka'nın bu durumda üyenin Onaylarım’a yüklemiş olduğu her türlü bilgiyi ve hesabı silme hakkına sahip olduğunu ve bu verileri iade yükümlülüğü olmadığını kabul eder. Hizmetlerin kullanımı sırasında Müşterinin gerçekleştirmemeyi kabul ve taahhüt ettiği hareketler bu sayılanlarla sınırlı olmamak ve örnekleyici olmak kaydı ile şunlardır:",
  },
  {
    type: "ul",
    items: [
      {
        text: "Anayasa ve Kanunlarına aykırı davranışlarda bulunmak, bu itibarla hakaret, iftira, sövme, tehdit, kişilik hakları ve özel hayatın gizliliğini ihlal başta olmak üzere kişilerin yasal haklarını ihlal eden davranışlar,",
      },
      {
        text: "Terör örgütünü ve suçu övme, fuhuş, sarkıntılık, çocukların cinsel istismarı, uyuşturucu madde kullanımını kolaylaştırma başta olmak üzere yürürlükteki yasalarla suç olarak tanımlanmış her türlü fiili gerçekleştirmek, bunları teşvik ve suçu övmek,",
      },
      {
        text: "Yürürlükte olan Internet Ortamında Yapılan Yayınların Düzenlenmesi ve Bu Yayınlar Yoluyla İşlenen Suçlarla Mücadele Edilmesi Hakkında 5651 Sayılı Kanun’un 8. maddesinde sayılanlar başta olmak üzere yalan, haksız, müstehcen, yakışıksız içerikleri veya yasa dışı materyal veya bilgiyi yayınlamak, postalamak, dağıtmak ve veya yaymak veya olay tarihinde yürürlükte olacak ilgili mevzuata aykırı hareketlerde bulunmak,",
      },
      {
        text: "Fikir ve Sanat Eserleri Kanunu, markalar mevzuatı ve yürürlükteki rekabet mevzuatına aykırı işlemler yapmak.",
      },
      {
        text: "Diğer şirket/şahıslara rahatsız edici davranış ve söylemlerde bulunmak, diğer şirket/şahıslara karşı yasalarla suç olarak kabul edilmiş olsun olmasın her türlü rahatsız edici ve zarar verici eylemi gerçekleştirmek.",
      },
      {
        text: "Onaylarım’ı kullanarak ticari faaliyetler yürütmek, diğer üyelere satış, reklam, pazarlama, tanıtım vb. faaliyetlerde bulunmak,",
      },
      {
        text: "Kendisini Seneka çalışanı, temsilcisi, yetkilisi vb. sıfatlarla tanıtmak ve menfaat elde etmek amacıyla ve kötü niyetle olsun olmasın herhangi bir şekilde Seneka kurumsal kişiliği ile bir bağlantısı olduğunu 3. şahıslara söylemek veya ima etmek.",
      },
      {
        text: "Seneka, Müşterilerin kişilikleri ile ilgili olarak Onaylarım’a aktardıkları tüm bilgilerin özel bir hassasiyetle kullanılmasının Müşteriler için çok önemli olduğunun bilincindedir. Bu nedenle Seneka, ilgili tüm yasal veri koruma mevzuatına uymaktadır (Türk Veri Koruma Yasaları, Avrupa Veri Koruma Yönergeleri ve burada uygulanabilecek diğer tüm yasal bilgi koruma mevzuatı). Seneka, Müşterilerle ilgili bilgileri özellikle yetkisiz 3. şahıslara vermeyecek veya 3. şahısları bu konuda bilgilendirmeyecektir.",
      },
      {
        text: "Seneka 5651 sayılı yasa gereği Müşterilerinin trafik verilerini ve bu yasa ile veya yürürlükte olan veya ileride yürürlüğe girecek olan diğer mevzuat ile tutulması gereken tüm verileri tutmaktadır. Müşteri yasal zorunluluk nedeni ile tutulmakta olan bu veriler nedeniyle iletişiminin mahremiyetinin veya diğer haklarının zarara uğradığı iddiasında bulunmayacağını kabul ve taahhüt eder. Seneka bu verilerin saklanmasında ve imhasında gerekli tüm özeni göstermeyi taahhüt eder.",
      },
      {
        text: "Seneka, Uygulama’yı “olduğu gibi” sağlamaktadır. Uygulama’nın hatasız olduğu veya Müşteri’nin tüm ihtiyaçlarını karşılayacağı taahhüdünde bulunmamaktadır.",
      },
    ],
  },

  {
    type: "h1",
    text: "4. Fikri Mülkiyet Hakları",
  },
  {
    type: "p",
    text: "Uygulama içerisindeki görsel ve tasarımlar, yazılar, logolar, grafikler de dahil olmak üzere, Seneka'ya ait sistemler ve Uygulama’nın tüm mali, manevi ve ticari hakları Seneka'ya aittir. Seneka, Müşteri’ye Uygulama üzerinde Sözleşme süresi ile sınırlı, münhasır olmayan ve devredilemez bir kullanım hakkı (basit ruhsat) vermektedir. Uygulamanın kullanımı söz konusu fikri mülkiyet hakları konusunda hiçbir hak vermez.",
  },
  {
    type: "p",
    text: "Müşteri, Uygulama’yı yalnızca bu Sözleşme’de belirtilen şekilde kullanabilir ve bu hakkı ödünç veremez, kiralayamaz, üçüncü şahısların yararına sunamaz, kullandıramaz.",
  },
  {
    type: "p",
    text: "Uygulamada bulunan bilgiler hiçbir şekilde çoğaltılamaz, yayınlanamaz, kopyalanamaz, sunulamaz ve/veya aktarılamaz.",
  },
  {
    type: "p",
    text: "Müşteri’nin Uygulama güvenliğini tehdit edebilecek zarar verebilecek eylemler gerçekleştirmesi, Uygulama’nın çalışmasına engel olacak işlemler yapması, tersine mühendislik yapması, saldırı yapması, Seneka sunucularına erişim sağlamaya çalışılması kesinlikle yasaktır.",
  },
  {
    type: "h1",
    text: "5. Mali Hükümler",
  },
  {
    type: "p",
    text: "Müşteri ihtiyacına göre başlattığı her bir süreç için ödeme yapabilir. Ödemeler kredi kartı veya havale yolu ile yapılabilir. Müşteri, ödeme yapılan kart veya hesap bilgilerini korumakla yükümlü olup söz konusu ödemelerin yapılması sonrasında ödemelerin bilgisi dışında veya yetkisiz olarak yapıldığını ileri süremez.",
  },
  {
    type: "p",
    text: "Süreç başlatmak için yapılan ödemelerin iadesi yapılmaz.",
  },
  {
    type: "p",
    text: "Müşteri ihtiyacına göre kredi yüklemesi için ödeme yapabilir. Ödemeler kredi kartı veya havale yolu ile yapılabilir. Müşteri, ödeme yapılan kart veya hesap bilgilerini korumakla yükümlü olup söz konusu ödemelerin yapılması sonrasında ödemelerin bilgisi dışında veya yetkisiz olarak yapıldığını ileri süremez.",
  },
  {
    type: "p",
    text: "Kredi yüklemesi için yapılan ödemelerin iadesi yapılmaz.",
  },
  {
    type: "p",
    text: "Seneka, ücretlerde dilediği zaman değişiklik yapma hakkına sahiptir.",
  },

  {
    type: "h1",
    text: "6. Sözleşme’nin Sona Ermesi ve Feshi",
  },
  {
    type: "p",
    text: "İşbu Sözleşme, Müşteri tarafından onaylanması ile yürürlüğe girer.",
  },
  {
    type: "p",
    text: "Seneka, Müşteri’nin Sözleşme’de yer alan şartlara uymaması ve/veya herhangi bir nedenle ya da neden sunulmaksızın, Müşteri’nin Uygulama’yı kullanımı geçici veya süresiz  olarak durdurabilir ve bu Sözleşme’yi tazminatsız olarak feshedebilir.",
  },
  {
    type: "p",
    text: "Tarafların kendi rızası ve/veya rızası iflas ve iflasın ertelenmesi hali, konkordato ya da faaliyetlerini durdurması halinde herhangi bir tazminat ödemeksizin feshedebilir.",
  },
  {
    type: "p",
    text: "Mücbir durumlarda Sözleşme’den doğan hak ve yükümlülükleri askıya alınır.",
  },
  {
    type: "p",
    text: "Müşterinin sözleşmeyi feshi durumunda Müşteri süreç başlatma ve kredi alma için yaptığı ödeme tutarlarının iadesini talep edemez. Kurumsal hesaplar, kullanıcı lisansı için yaptığı ödemelerin için iade talep edebilir. İade edilecek miktar, iade talep tarihinden lisans geçerlilik tarihine kadar süre için hesaplanan miktardır.",
  },
  {
    type: "h1",
    text: "7. Muhtelif Hükümler",
  },
  {
    type: "p",
    text: "Meydana gelebilecek uyuşmazlıklarda, HMK 193. madde kapsamında Seneka tarafında tutulan (elektronik kayıtlar da dahil olmak üzere) kayıtlar delil kabul edilecektir.",
  },
  {
    type: "p",
    text: "Seneka, bu Sözleşmeyi her zaman, neden belirtmeksizin değiştirme hakkını saklı tutar. Sözleşme değişikliği durumunda, Uygulama’nın Müşteri tarafından bir sonraki kullanımı ile birlikte yürürlüğe girecektir. Sözleşmenin ve değişikliklerinin takip edilmesinden Müşteri sorumludur.",
  },
  {
    type: "p",
    text: "Taraflar arasında doğabilecek uyuşmazlıklarda Ankara Mahkemeleri ve İcra Daireleri yetkilidir.",
  },
];

const eulaContent = {
  title: "KULLANIM SÖZLEŞMESİ",
  subTitle: "Son Kullanıcı Sözleşmesi",
  steps: eulaSteps,
};

export default eulaContent;
